/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { TroApi /*, WorkspaceApi*/ } from '@/api';
import {
  TroProjectOverview,
  TroProjectOverviewStatusEnum,
  WorkspaceAccountProfile
} from '@/api/openapi';
import { handleError } from '@/common/handleError';
import { toast } from '@/components/ui/Toast';
import useLoading from '@/composables/loading';
import { PLANNING_ROUTES, ROUTE_NAMES } from '@/router';
import { useUserInfoStore } from '@/store/userInfo';
import Axios from 'axios';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

interface ProjectAccount extends WorkspaceAccountProfile {
  avatar: string | null;
}

export interface ExTroProjectOverview extends TroProjectOverview {
  account: ProjectAccount | undefined;
  formatStartDate: Date;
  formatEndDate: Date;
  formatedUpdatedAt: Date;
  formatNumOfEffectiveContacts: number;
}

export const useTroProjectList = (companyId?: string) => {
  const router = useRouter();
  const route = useRoute();
  const troProjectList = ref<any[]>([]);

  const getWorkspaceId = async (currentCompanyId?: string) => {
    const companyId = currentCompanyId ?? route.params.companyId.toString();
    const userInfoStore = useUserInfoStore();
    if (userInfoStore.joinableCompanies.length === 0) {
      await userInfoStore.fetchJoinableCompanies();
    }
    const companyInfo = userInfoStore.getCompanyInfo(companyId);
    return companyInfo?.workspaceId ?? 0;
  };

  const _fetchProjectList = async () => {
    try {
      if (!companyId) {
        toast({ title: 'カンパニーが選択されていません', variant: 'error' });
        troProjectList.value = [];
        return;
      }
      const res = await TroApi.getCmPlanningCompaniesCompanyIdTroProjects(
        Number(companyId)
      );
      if (200 <= res.status && res.status < 300) {
        const workspaceId = await getWorkspaceId(companyId);
        if (workspaceId === 0) {
          troProjectList.value = [];
          return;
        }
        const list = res.data.list.map(async v => {
          return {
            ...v,
            formatStartDate: new Date(v.startDate),
            formatEndDate: new Date(v.endDate),
            formatedUpdatedAt: new Date(v.updatedAt),
            formatNumOfEffectiveContacts: Number(
              v.numOfEffectiveContacts.replace(/[^0-9]/g, '')
            )
          } as ExTroProjectOverview;
        });
        troProjectList.value = await Promise.all(list);
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクト一覧の取得に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      toast({
        title: '失敗',
        message: 'プロジェクト一覧の取得に失敗しました',
        variant: 'error'
      });
    } finally {
      // ループ処理が画面遷移後に実行されていた場合停止
      setTimeout(async () => {
        if (isStopLoopRequest.value) stopLoopRequest(intervalId.value);
      }, 1000);
    }
  };
  const [isFetchProjectList, fetchProjectList] = useLoading(_fetchProjectList);

  const _deleteProject = async ({
    projectId,
    projectName
  }: {
    projectId: number;
    projectName: string;
  }) => {
    try {
      const res = await TroApi.deleteCmPlanningTroProjectsTroProjectId(
        projectId
      );
      if (200 <= res.status && res.status < 300) {
        fetchProjectList(companyId);
        toast({
          title: '成功',
          message: '「 ' + projectName + '」を削除しました',
          variant: 'success'
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの削除に失敗しました',
          variant: 'error'
        });
      }
    } catch (e) {
      if (Axios.isAxiosError(e)) {
        handleError(e, 'プロジェクトの削除に失敗しました', '');
      } else if (e.status == 403) {
        toast({
          title: '失敗',
          message:
            'プロジェクトの削除に失敗しました。アクセス権限がありません。',
          variant: 'error'
        });
        router.push({
          name: ROUTE_NAMES.error,
          params: {
            name: '権限がありません。',
            message:
              'アクセス先に権限がありません。\n連続してこのページが表示される場合は管理者にお問い合わせください。',
            back: undefined,
            status: '403 forbidden'
          },
          force: true
        });
      } else {
        toast({
          title: '失敗',
          message: 'プロジェクトの削除に失敗しました',
          variant: 'error'
        });
      }
    }
  };
  const [isDeleteProjectList, deleteProject] = useLoading(_deleteProject);

  const searchingList = computed(() => {
    return troProjectList.value.filter(
      v =>
        v.status == TroProjectOverviewStatusEnum.Searching ||
        v.status == TroProjectOverviewStatusEnum.SearchingQueued
    );
  });

  // ループ処理関連
  const intervalId = ref();
  const isStopLoopRequest = ref(false);
  const stopLoopRequest = id => {
    clearInterval(id);
  };
  const loopRequest = async () => {
    intervalId.value = setTimeout(async () => {
      if (!isFetchProjectList.value && searchingList.value.length > 0) {
        await fetchProjectList(companyId);
      }
      loopRequest();
    }, 10000);
  };

  const goToResult = async (projectId: number) => {
    await router.push({
      name: PLANNING_ROUTES.result,
      params: { ...route.params, projectId }
    });
  };

  const goToCreate = async () => {
    await router.push({
      name: PLANNING_ROUTES.create,
      params: { ...route.params }
    });
  };

  const goToClone = async (projectId: number) => {
    await router.push({
      name: PLANNING_ROUTES.clone,
      params: { ...route.params, projectId: projectId }
    });
  };

  const goToEdit = async (projectId: number) => {
    await router.push({
      name: PLANNING_ROUTES.edit,
      params: { ...route.params, projectId: projectId }
    });
  };

  const getIconName = (value: TroProjectOverviewStatusEnum) => {
    switch (value) {
      case TroProjectOverviewStatusEnum.NotExecuted:
      case TroProjectOverviewStatusEnum.NotInContractArea:
      case TroProjectOverviewStatusEnum.NeedToEdit:
        return 'exclamation';
      case TroProjectOverviewStatusEnum.NotExecutable:
      case TroProjectOverviewStatusEnum.NoEffectiveCombination:
        return 'ban';
      case TroProjectOverviewStatusEnum.Searching:
      case TroProjectOverviewStatusEnum.Referable:
      case TroProjectOverviewStatusEnum.SearchingQueued:
      default:
        return '';
    }
  };

  const gettoolTipMessage = (value: TroProjectOverviewStatusEnum) => {
    switch (value) {
      case TroProjectOverviewStatusEnum.NotExecuted:
        return '編集画面から実行してください';
      case TroProjectOverviewStatusEnum.NotInContractArea:
        return '契約のないエリアが設定されています';
      case TroProjectOverviewStatusEnum.NeedToEdit:
        return '使用できないオプションが含まれているので確認してください';
      case TroProjectOverviewStatusEnum.NotExecutable:
        return '分析に失敗しました。再度、実行してください。';
      case TroProjectOverviewStatusEnum.NoEffectiveCombination:
        return '条件を満たす組み合わせはありません';
      case TroProjectOverviewStatusEnum.Searching:
      case TroProjectOverviewStatusEnum.Referable:
      case TroProjectOverviewStatusEnum.SearchingQueued:
      default:
        return '';
    }
  };

  return {
    getIconName,
    gettoolTipMessage,
    troProjectList,
    fetchProjectList,
    deleteProject,
    isDeleteProjectList,
    intervalId,
    isStopLoopRequest,
    stopLoopRequest,
    loopRequest,
    goToResult,
    goToCreate,
    goToClone,
    goToEdit
  };
};

export const useDataTable = (): any => {
  const textFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '含む', value: FilterMatchMode.CONTAINS },
    { label: '含まない', value: FilterMatchMode.NOT_CONTAINS }
  ]);
  const numberFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.EQUALS },
    { label: '等しくない', value: FilterMatchMode.NOT_EQUALS },
    { label: '以上', value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO },
    { label: '以下', value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    { label: 'より大きい', value: FilterMatchMode.GREATER_THAN },
    { label: 'より小さい', value: FilterMatchMode.LESS_THAN }
  ]);
  const dateFilterMatchModes = ref([
    { label: '等しい', value: FilterMatchMode.DATE_IS },
    { label: '含まない', value: FilterMatchMode.DATE_IS_NOT },
    { label: '以前', value: FilterMatchMode.DATE_BEFORE },
    { label: 'より後', value: FilterMatchMode.DATE_AFTER }
  ]);
  const filters = ref({
    projectName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    targetName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    },
    formatNumOfEffectiveContacts: {
      operator: FilterOperator.AND,
      constraints: [
        { value: null, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO }
      ]
    },
    formatStartDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    },
    formatEndDate: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    },
    formatedUpdatedAt: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }]
    },
    authorSaasAccountName: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
    }
  });

  return {
    textFilterMatchModes,
    numberFilterMatchModes,
    dateFilterMatchModes,
    filters
  };
};
